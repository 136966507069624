/* These two styles rotate the card and postion them */
.aftermath-card-half-top {
    order: 1;
    height: 390px;
    transform: translateX(-5px);
    /* margin-left: 20px; */
}

.aftermath-card-half-bottom {
    transform: rotate(90deg)  translate(35px, 0px); 
    order: 2;
    transform-origin: bottom left;
}

.aftermath-card-background {
 height: 180px;
 width: 235px;
 margin-top: 30px;
 transform-origin: top;
}

.aftermath-bottom-card-background {
 height: 235px;
 width: 105px;
 margin-left: 5px ;
 background-color: #bbb;
 position: absolute;
}

.aftermath-card-frame {
 z-index: 1;
 position: relative;
 top: 4.5%;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
  transform: scale(1.02)
}


.aftermath-text-box {
 position: absolute; /* Allows the box to be positioned relative to the nearest positioned ancestor */
 bottom: -55px;
 margin: 0px 8px;
 height: 55px;
 width: 215px;
 background: #d3ded6;
 display: flex;
 flex-direction: column;
 justify-content: space-around; 
 box-sizing: border-box;
 overflow: hidden;
}

.aftermath-bottom-text-box {
 position: absolute; /* Allows the box to be positioned relative to the nearest positioned ancestor */
 bottom: -80px;
 margin: 0px 6px;
 height: 80px;
 width: 95px;
 background: #d3ded6;
 display: flex;
 flex-direction: column;
 justify-content: space-around; 
 box-sizing: border-box;
 overflow: hidden;
}

.aftermath-bottom-card-background > .aftermath-bottom-card-frame {
    transform: scale(1.2)  translate(0px, 12px);;
}