.card-container {
 color: black;
 border: 1px solid black;
 width: 250px;
 height: 350px;
 padding: 3px;
 position: relative;
 justify-content: center;
 align-items: center;
 text-align: center;
 display:flex;
 border-radius: 6px;
 box-sizing: border-box;
 background: #171314;
 margin: 0;
}

.basic-card-background {
 height: 320px;
 width: 235px;
}

.card-background {
 margin: 10% 2% 15% 2%;
 border-radius: 6px;
 border-top-right-radius: 6px;
 border-bottom-left-radius: 8%;
 border-bottom-right-radius: 8%;
 background-color: #bbb;
 position: relative;
 background-position: center;
 background-repeat: no-repeat; /* Do not repeat the image */
 background-size: cover; /* Resize the background image to cover the entire container */
}

.card-frame {
 z-index: 1;
 position: relative;
 width: 100%; /* adjusted from 244px */
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 height: 315px;
 margin-top: 5px;
}

.frame-header,
.frame-type-line {
    background: 
        linear-gradient(0deg, rgba(201, 216, 201, .3), rgba(201, 216, 209, .3)); 
    display: flex;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 93%; /* or whatever percentage or pixel value you prefer */
    padding: 8px 0;
    justify-content: space-between;
    border-radius: 18px/40px !important;
    font-size: clamp(0.3em, .2em, .5em);
    position: relative;
    height: 2px;
    align-items: center;
    z-index: 1;
}

.name,
.type {
 margin-left: 5px;
 font-weight: 600;
 border-radius: 50% !important;
}

.frame-image {
    width: 91%; 
    height: 47%; 
    position: relative;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    z-index: 0;
}

.frame-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:fill;
    object-position: center;
}

.mana-icon {
 border-radius: 50%;
 box-sizing: border-box;
 box-shadow: -0.05em 0.12em 0px 0 black;
 height: 15px;
 object-fit: cover;
   margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
 
}

.mana-icon {
  vertical-align: px; /* or '-2px', etc. */
}

#set-icon {
 height: 9%;
 width: 6%;
 overflow: hidden;
 margin-right: 10px;
 align-self: center;
}

.frame-text-box {
 margin: 0px 10px;
 background: #d3ded6;
 display: flex;
 flex-direction: column;
 box-sizing: border-box;
 position: relative;
 height: 125px; 
 justify-content: space-around;
 align-items: space-around;
}
 
.flavour-text {
 font-style: italic;
 padding: 3px 0;
}

p {
 margin-bottom: 1px;
 margin-top: 0px;
 height: 100%; 
}

.power-toughness {
    position: absolute;
    bottom: -12px; 
    right: -5px; 
    width: 35px; 
    height: 17px; 
    background-color: #ccc; 
    border-radius: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    z-index: 2;
    border: 1px solid black;
    font-size: clamp(0.5em, .8em, 2em);
}

.defense {
    position: absolute;
    bottom: -1px; 
    right: -10px; 
    width: 35px; 
    height: 25px; 
    background-color: #ccc; 
    border: 1px solid black;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    font-size: clamp(0.5em, .8em, 2em);
    z-index: 1;
}

.planeswalker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.planeswalker-cost-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .3em; /* Adjust as needed */
}

.planeswalker-cost {
    position: absolute;
    font-size: 0.6em;  
    color: white;  
}

.planeswalker.highlight {
    background-color: rgba(186, 0, 0, 0.1); /* Adjust the rgba value as needed */
}

.mana-cost {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.ability {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ability-cost-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em; /* Adjust as needed */
}

.ability-cost {
    position: absolute;
    font-size: 0.6em;  
    color: white;  
}

.planeswalker_abilities {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.levels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.level-stats {
    min-width: 25px; 
    height: 17px; 
    background-color: #ccc; 
    border-radius: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    z-index: 1;
    border: 1px solid black;
    font-size: clamp(0.5em, .8em, 2em);
}

.card-color-border::before {
    content: "";
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    right: -1.5px;
    bottom: -1.5px;
    border: 1px solid black;
    z-index: 2;
    border-radius: 18px/40px;
    box-sizing: border-box;
}

.card-color-border-square::before {
    content: "";
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    right: -1.5px;
    bottom: -1.5px;
    border: 1px solid black;
    z-index: 1;
    box-sizing: border-box;
}

.textcontainer {
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: space-around; /* Spread children evenly with space around them */
  align-items: center; /* Center children horizontally */
  height: 100%;
}

.flavor-text {
    font-style: italic;
    /* Other styling as needed... */
}