/* These two styles rotate the card and postion them */
.card-half-top {
    transform: rotate(-90deg)  translate(0, 158px); 
    transform-origin: center left;
    order: 1;
}

.card-half-bottom {
    transform: rotate(-90deg)  translate(-30px, 138px); 
    order: 2;
    transform-origin: bottom left;
}

.split-card-background {
 height: 230px;
 width: 155px;
}

.split-card-frame {
 z-index: 1;
 position: relative;
 max-width: 97%;
 max-height: 97%;
 left: 1.2%;
 top: 0.5%;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
}

.frame-split-image {
    width: 90%; 
    height: 65%; 
    position: relative;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

.frame-split-image img {
    width: 100%;
    height: 100%;
    object-fit:fill;
    object-position: center;
    vertical-align: bottom;
}