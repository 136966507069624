.bottom-section {
 display: flex;
 justify-content: space-between;
 height:  38%;
}

.adventure-bottom {
 display: flex;
 flex-direction: column;
 width: 120px;
}

.adventureframe-header,
.adventureframe-type-line {
 background: linear-gradient( 0deg, rgba(201, 216, 201, .3), rgba(201, 216, 209, .3) );
 display: flex;
 margin-top: 0px;
 padding: 8px 0;
 justify-content: space-between;
 border-radius: 5px 5px;
 font-size: .4em;
 position: relative;
 height: 4px;
 align-items: center;
 width: 108px;;
 margin: 0 6px;
  font-size: clamp(0.2em, .28em, .8em);
  flex-wrap: nowrap;
}

.adventureframe-text-box{
 margin: 0 10px;
 height: 85px;
 width: 90%;
 background: #d3ded6;
 display: flex;
 flex-direction: column;
 justify-content: space-around; 
 box-sizing: border-box;
font-size: clamp(0.4em, 1em, 2em);
 position: relative;
}

.adventureer-text-box {
 width: 100%;
 height: 100%;
 margin-left: -5px;
 background: #d3ded6;
}

.adventureframe-header {
    justify-content:space-between;
}

.adventureframe-header .mana-icon {
    height: 12px; /* adjust this value to suit your needs */
}

.card-color-border-adventure::before {
    content: "";
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    right: -.5px;
    bottom: -1.5px;
    border: 1px solid black;
    z-index: 2;
    border-radius: 18px/40px;
    box-sizing: border-box;
}