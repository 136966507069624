.saga-container {
    display: flex;
    flex-direction: row;
    justify-content: center; 
}

.saga-frame-image {
    max-width: 96px;  
    height: auto;  
    background-color: #ccc;
}

.saga-frame-image img {
    width: 100%;
    height:  100%;
}

.saga-frame-text-box {
 width: 50%;  
 height: 275px;
 background: #d3ded6;
 display: flex;
 flex-direction: column;
 justify-content: flex-start; 
 box-sizing: border-box;
 position: relative;
 align-items: center;
}

.roman-numeral {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white; 
    font-weight: bold;
    width: 25px;
}

.roman-numeral svg {
    color: black;
}

.roman-numeral span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    font-size: 0.7em; 
    z-index: 2;
}

.saga_oracle {
    border-top: 1px solid #000;
    padding-top:5px;
    height: auto;
    position: relative;
}

.frame-type-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hex-icon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.numeral-container {
  display: flex;
  flex-direction: row;
  align-items: center; /* this is now horizontal alignment due to row direction */
  justify-content: flex-start;
  position: relative;
  margin-right: .5em; /* add some space between the numeral and the text */
}

.numeral-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.numeral-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.abilities {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;
  
}

.saga_ability {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:  flex-start;
  width: 100%;
  flex: 1; /* share available height */
  
  
}

.saga_text .textcontainer {
  height: auto; /* or you could use a specific value like '50%' */
}
/* text-align: center; */