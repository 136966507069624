body {
  background-color: #222;
  color: #fff;
  font-family: Arial, sans-serif;
  text-align: center;
  padding-top: 50px;
  margin: 0; /* To remove default margins */
    height: 100%;
}


.container {
  display: flex;
  flex-direction: column;
  height: 100%;
 /* To stack Header and content vertically */
}

.content {
  flex-grow: 1; /* Allow content to grow and take up remaining space */
  display: flex;
  height: 100%;
}

.header {
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  flex-grow: 1;
  text-align: center;
}


.input-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #333;
  border: none;
  color: #fff;
  font-size: 16px;
}

.submit-button {
  background-color: #555;
  color: #fff;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #777;
}

.card-result {
  margin-bottom: 20px;
}

.card-name {
  font-size: 18px;
  font-weight: bold;
}

.card-image {
  max-width: 200px;
  margin-top: 10px;
}

.card-text {
  margin-top: 10px;
}

.card-rules {
  font-style: italic;
  margin-top: 10px;
}

#card-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;  /* Align items to the left */
  gap: 10px;  /* Adjust as needed */
}

.sidebar {
  width: 200px;
  background-color: #333;
  color: #fff;
  padding: 10px;
  flex-shrink: 0;
  height: 100%;
  position: fixed; /* Prevent shrinking */
}

.card-form-container { /* Apply this class to the CardForm component */
  flex-grow: 1; /* Allow it to take up the remaining available width */
  margin-left: 250px;
  margin-right: 50px;
  width: 100%;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}

.sidebar textarea {
  width: 90%;
  padding: 5px;
  background-color: #333;
  border: none;
  color: #fff;
  resize: none; /* Prevent resizing */
}

.sidebar label {
  display: block;
  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column; /* Stack the children (textarea and buttons) vertically */
  align-items: center; /* Center the children horizontally */
}

.form-buttons {
  display: flex;
  flex-direction: row; /* Stack the children (textarea and buttons) vertically */
  align-items: center; /* Center the children horizontally */
}

.submit-button,
.clear-button {
  margin: 10px;
}

.patreon-button {
  width: 100px;
  height: 50px;
  margin-left: 50px;
}