.flip {
    transform: rotate(180deg);
}

.flip-frame-text-box {
 margin: 0 10px;
 height: 50px;
 background: #d3ded6;
 display: flex;
 flex-direction: column;
 justify-content: space-around; 
 box-sizing: border-box;
 position: relative;
}

.flip-card-background {
 height: 335px;
 margin: 15% 2% 15% 2%;
 border-radius: 6px;
 border-top-right-radius: 6px;
 border-bottom-left-radius: 6px;
 border-bottom-right-radius: 6px;
 background-color: #bbb;
 position: relative;
}

.flip-power-toughness {
    position: absolute;
    bottom: -12px; 
    right: -5px; 
    width: 35px; 
    height: 17px; 
    background-color: #ccc; 
    border-radius: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    font-size: 3em;
    z-index: 2;
}