.battle-card-background {
    width: 130%;
    height: 70%;
    position: absolute; /* Change from relative to absolute */
    overflow: hidden;
    transform: rotate(270deg);
    top: -10; /* Add these lines to position as desired */
    left: -10; 
}

.battle-card-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.battle-frame-header,
.battle-frame-type-line {
    display: flex;
    justify-content: space-between;
    width: 90%; /* Adjust as needed */
    height: 10%; /* Adjust as needed */
    /* padding: 5px 0; */
    font-size: clamp(0.4em, .3em, .8em);
    background: linear-gradient( 0deg, rgba(201, 216, 201, .3), rgba(201, 216, 209, .3) ); 
    border-radius: 18px/40px;
}

.battle-frame-image {
    flex-grow: 1;
    width: 88%; /* Adjust as needed */
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.battle-frame-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    
}

.battle-frame-text-box {
    width: 88%; /* Adjust as needed */
    height: 35%; /* Adjust as needed */
    flex-direction: column;
    justify-content: space-between; 
    box-sizing: border-box;
    align-items: start;
    background: #d3ded6;
    font-size: clamp(0.5em, 1.1em, 3.5em);
}

